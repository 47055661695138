@use "../../styles/variables" as vars;
@import 'bootstrap';

.profile-form {
  @extend .h-100;
  @extend .px-1; 
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-between;
  @extend .text-center;

  &_title {
    font-size: 1.75rem;
    margin-top: 0.75em;
    color: vars.$tbc-color-new-blue;
  }
  &_subtitle {
    font-size: 1.6rem;
    color: vars.$tbc-color-new-blue;
  }
  &_text {
    color: vars.$tbc-color-new-blue;
  }
  &_form-icon {
    text-align: center;
    color: vars.$tbc-color-new-blue;
    margin: 2rem auto;
  }
  .form-label {
    color: vars.$tbc-color-new-blue;
  }
  &_custom-identity {
    input {
      text-align: center;
      color: #0d6efd;      
    }
  }
  &_image-ctx-menu-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    color: vars.$tbc-color-gray-100;
    border: 2px solid vars.$tbc-color-new-blue;
    border-radius: 50% 50%;
    z-index: 20;
    &:hover {
      color: vars.$tbc-color-new-blue;
    }
  }
  &_video {
    max-height: 200px;
  }
  .range-slider-field {
    &_value {
      color: vars.$tbc-color-new-blue;
      margin-bottom: 10px;
    }
  }
}
