.preview-modal {
  .modal-content {
    background-color: #111;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
}

.crop-modal {
  .modal-content {
    background-color: #333;
    color: #fff;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
    justify-content: space-around;
  }
}