@use "../../styles/variables" as vars;

.footer {
  &_title {
    font-size: 1.75rem;
    color: vars.$tbc-color-purple;
    text-transform: uppercase;
  }
  
  &_subtitle {
    font-size: 1.25rem;
    color: vars.$tbc-color-purple;
    text-transform: uppercase;
  }
  
  &_form {
    height: 100%;
    padding: 50px 15px;
  }
}
