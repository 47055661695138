@use '../../styles/variables.scss' as vars;

.competition-sections {
  &_section {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  &_section-name {
    text-transform: uppercase;
    font-size: calc(1rem + 0.9vw);
    font-weight: 700;
    text-align: center;
  }
}
