@use "../../styles/variables" as vars;

.signup {
  &_title {
    font-size: 1.75rem;
    color: vars.$tbc-color-new-blue;
    text-transform: uppercase;
  }
  &_form {
    display: inline-block;
    height: 100%;
    padding: 50px 0;
  }
}
