@use "../../styles/variables" as vars;

.calendar-container {
  h2 {
    margin: 0.5em 0.25em;
    color: vars.$tbc-color-new-blue;
    font-size: 20px;
    font-weight: 700;
  }
  &_footer-navigation {
    margin-bottom: 2em;
    padding: 10px;
    color: vars.$tbc-color-new-blue;
  }
}

.react-calendar {
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75em;
  background-color: vars.$tbc-color-off-white;
  & abbr {
    text-decoration: none;
  }
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.75em 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  border-radius: vars.$border-radius;
  max-width: 100%;
  padding: 10px 10px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile--now {
  font-weight: 600;
  color: vars.$tbc-color-red;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  font-weight: 600;
  color: vars.$tbc-color-red;
  // background: vars.$tbc-color-white;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  color: black;
  font-weight: 800;
  background-color: vars.$tbc-color-gray-300;
}
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 8px;
}
.dot {
  display: inline-flex;
  height: 5px;
  width: 5px;
  margin: 2px 2px;
  border-radius: 4px;
  &--has-competition {
    background-color: vars.$tbc-color-new-blue;
  }
  &--has-trainingcamp {
    background-color: vars.$tbc-color-orange;
  }
}
.react-calendar__tile--active.react-calendar__tile--has-event {
  font-weight: 600;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
