@use "../../styles/variables" as vars;

.profile-edit {
  &_item {
    color: vars.$tbc-color-new-blue;
  }
  &_icon-container {
    margin-right: 2rem;
    color: vars.$tbc-color-new-blue;
    &_icon {
      height: 36px;
      width:36px;
    }

  }
}
