@use 'variables' as vars;

@mixin slider-thumb {
  border: 2px solid vars.$tbc-color-new-blue;
  height: 36px;
  width: 36px;
  background: #ffffff;
  cursor: pointer;
}

@mixin slider-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
}

$track-border: 0.2px solid #e9ecef;

input[type=range] {
  /* Special styling for WebKit/Blink */
  &::-webkit-slider-thumb {
    @include slider-thumb;
    -webkit-appearance: none;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }
  &::-webkit-slider-runnable-track {
    @include slider-track;
    background: vars.$tbc-color-new-blue;
    border-radius: 1.3px;
    border: $track-border;
  }
  &:focus::-webkit-slider-runnable-track {
    background: vars.$tbc-color-new-blue;
  }
  /* All the same stuff for Firefox */
  &::-moz-range-thumb {
    @include slider-thumb;
  }
  &::-moz-range-track {
    @include slider-track;
    background: vars.$tbc-color-new-blue;
    border-radius: 1.3px;
    border: $track-border;
  }
  /* All the same stuff for IE */
  &::-ms-thumb {
    @include slider-thumb;
  }
  &::-ms-track {
    @include slider-track;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: vars.$tbc-color-new-blue;
    border: $track-border;
    border-radius: 2.6px;
  }
  &:focus::-ms-fill-lower {
    background: vars.$tbc-color-new-blue;
  }
  &::-ms-fill-upper {
    background: vars.$tbc-color-new-blue;
    border: $track-border;
    border-radius: 2.6px;
  }
  &:focus::-ms-fill-upper {
    background: vars.$tbc-color-new-blue;
  }
}
