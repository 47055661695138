@use './variables.scss' as vars;

.accordion {
  margin: 0.5rem;
  .accordion-item {
    border-left: 2px solid vars.$tbc-color-new-blue;
    border-right: 2px solid vars.$tbc-color-new-blue;
    border-bottom: 2px solid vars.$tbc-color-new-blue;
    border-top: 2px solid vars.$tbc-color-new-blue;
    border-bottom-left-radius: vars.$border-radius;
    border-bottom-right-radius: vars.$border-radius;
    border-top-left-radius: vars.$border-radius;
    border-top-right-radius: vars.$border-radius;
    margin-bottom: 0.5rem;
    &:first-of-type {
      border-top-left-radius: 0.35rem;
      border-top-right-radius: 0.35rem;
      background-color: vars.$tbc-color-new-blue;
    }
    &:not(:first-of-type) {
      border-top: 2px solid vars.$tbc-color-new-blue;
    }
  }
  .accordion-collapse.collapsing, .accordion-collapse.collapse.show {
    background-color: #FFF;
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
  }
  .accordion-header {
    text-align: center;
    background-color: vars.$tbc-color-new-blue;
    border-top-left-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
  }
  .accordion-button {
    background-color: vars.$tbc-color-new-blue;
    color: vars.$tbc-color-white;
    border-top-left-radius: vars.$border-radius;
    border-top-right-radius: vars.$border-radius;
    text-transform: uppercase;
    &:not(.collapsed) {
      color: #fff;
      font-weight: 400;
      background-color: vars.$tbc-color-gray-600;
      box-shadow: none;
      border: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.training-camp {
  --bs-accordion-btn-color: #FFF;
  .accordion-item {
    border-left: 2px solid vars.$tbc-color-orange;
    border-right: 2px solid vars.$tbc-color-orange;
    border-bottom: 2px solid vars.$tbc-color-orange;
    border-top: 2px solid vars.$tbc-color-orange;
    &:first-of-type {
      background-color: vars.$tbc-color-orange;
    }
    &:not(:first-of-type) {
      border-top: 2px solid vars.$tbc-color-orange;
    }
  }
  .accordion-button {
    background-color: vars.$tbc-color-orange;
  }
}
