@use "../../styles/variables" as vars;

.home {
  h4 {
    color: vars.$tbc-color-new-blue;
  }
  &_link {
    font-size: 1rem;
    font-weight: 500;
    color: vars.$tbc-color-new-blue;
    text-decoration: none;
    &:hover {
      color: vars.$tbc-color-gray-900;
      text-decoration: underline;
    }
  }
  
  &_primary-tile {
    width: 45%;
    text-align: center;
    border-radius:vars.$border-radius;
    margin: 0.5em;
    padding: 1.5rem;
    background-color: vars.$tbc-color-white;
    color: vars.$tbc-color-new-blue;
  }
  
  &_tile {
    width: 100%;
    border-radius:vars.$border-radius;
    margin: 0.5em;
    padding: 0.75rem;
    background-color: vars.$tbc-color-white;
    color: vars.$tbc-color-new-blue;
  }
  
  &_tile-icon {
    margin-right: 1.5rem;
  }
}
