@use "../../styles/variables" as vars;

.event-tiles_month-group {
  margin: 0.25rem 0.25rem 2rem 0.25rem;
  h2 {
    font-size: calc(1rem + .9vw);
    font-weight: 600;
    color: vars.$tbc-color-new-blue;
  }
}

.event-tiles_event-group {
  margin: 0 0 1.5rem 0;
  h3 {
    font-size: calc(1.125rem + .9vw);
    font-weight: 400;
    color: vars.$tbc-color-new-blue;
  }
}

.event-tile-link {
  text-decoration: none;
}

.event-tile {
  width: 100%;
  border-radius: vars.$border-radius;
  border-width: 2px;
  margin-bottom: 1rem;

  &--competition {
    color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
  }
  &--trainingcamp {
    color: vars.$tbc-color-orange;
    border-color: vars.$tbc-color-orange;
  }

  &_header {
    background-color: vars.$tbc-color-white;
    border-bottom: none;
    font-weight: 800;
    text-transform: capitalize;
  }

  &_event-type {
    text-transform: uppercase;
  }
  &_image {
    width: 30%;
    max-width: 100%;
    height: auto;
    object-fit: scale-down;
  }
}
