@use "../styles/variables" as vars;

.layout-container {
  padding-left: 0px;
  padding-right: 0px;
  &_sign-in {
    text-align: center;
    height: 100%;
  }
  &_start {
    text-align: center;
    height: 100%;
    color: vars.$tbc-color-new-blue;
  }
  &_logged-in, &_event-view {
    height: calc(100% - 80px);
    padding-top: 42px;
    overflow-x: auto;
    &--off-white {
      background-color: vars.$tbc-color-off-white;
    }
  }
}