.preview-modal {
  &_navigation {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 100%;
    color: #fff;
    width: 30px;
    top: 0;
    margin: 48px 0px;
    &_prev {
      left: 0;
    }
    &_next {
      right: 0;
    }
  }
}