@use "../../styles/variables" as vars;

.competition-preference-form {
  border-radius: vars.$border-radius;
  background-color: vars.$tbc-color-new-blue-lightest;
  padding: 0.75em;
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  &_remove-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0.75em 0;
    color: vars.$tbc-color-red;
  }
  .form-select {
    color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
  }
}