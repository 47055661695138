@use "../../styles/variables" as vars;

.video-upload-field {
    &_dropzone {
        border: 2px dotted vars.$tbc-color-new-blue;
        border-radius: 10px;
        padding: 2.5rem;
        width: 125px;
        height: 125px;
        margin: 0 auto;
        color: #999;
    }
    &_upload-icon {
        color: vars.$tbc-color-new-blue;
    }

    &_image-preview {
        width: 33.3%;
        text-align: center;
    }
}
