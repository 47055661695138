@use "../../styles/variables" as vars;

.login {
  &_title {
    font-size: 1.75rem;
    color: vars.$tbc-color-new-blue;
    text-transform: uppercase;
  }
  &_subtitle {
    font-size: 1.25rem;
    color: vars.$tbc-color-new-blue;
    text-transform: uppercase;
  }
  &_form {
    display: inline-block;
    height: 100%;
    padding: 50px 0;
  }
  &_link {
    font-size: 1rem;
    font-weight: 500;
    color: vars.$tbc-color-new-blue;
    text-decoration: none;
  }
}
