.profile-photo {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  img {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
