@use "../../styles/variables" as vars;

.logged-in-user {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  color: vars.$tbc-color-new-blue;
  
  &_image-container {
    width: 90px;
  }
}
