@use "../../styles/variables" as vars;
@import 'bootstrap';

.profile-view {
  color: vars.$tbc-color-new-blue;
  &_cover-image {
    width: 100%;
  }
  &_cover-video {
    max-height: 250px;
  }
  &_image-container {
    margin-top: -60px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 50%;
    img {
      border: 10px solid #ffffff;
      width: 100%;
    }
  }
  &_header-container {
    margin: 0.5rem;
    h1 {
      color: vars.$tbc-color-new-blue;
      font-weight: 700;
    }
    span {
      margin-right: 0.2rem;
      padding: 0 0.25rem;
      color: #ffffff;
      background-color: vars.$tbc-color-orange;
      border-radius: vars.$border-radius;
    }
  }
  &_section {
    @extend .mb-3;
    @extend .ms-1;
    @extend .me-0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &_no-border {
      border: 0;
    }
  }
  &_section-heading {
    font-weight: 800;
    text-transform: uppercase;
  }
}
