@use "../../styles/variables" as vars;

.events-list {
  &_icon-button {
    color: vars.$tbc-color-new-blue;
    margin: 1em 0.5em 1em 0;
    padding: 0.25em 0.75em;
    &--selected {
      color: vars.$tbc-color-orange;
    }
  }
  &_event-filters {
    background-color: vars.$tbc-color-off-white;
    &_title {
      padding-left: 32px;
      margin: 0 auto;
      color: vars.$tbc-color-new-blue;
    }
  }
}
