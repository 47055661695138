@use 'variables' as vars;

$border-style-blue: 2px solid vars.$tbc-color-new-blue;
$border-style-white: 0px solid vars.$tbc-color-white;

.form-label {
  font-weight: 600;
}

.form-control,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top: $border-style-blue;
  border-right: $border-style-blue;  
  border-bottom: $border-style-blue; 
  border-left: $border-style-blue;
  border-radius: vars.$border-radius;
}

.profile-form {
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: vars.$border-radius;
    border-bottom-right-radius: vars.$border-radius;
  }
  .btn-group > .btn:nth-child(n+3) {
    border-top-left-radius: vars.$border-radius;
    border-bottom-left-radius: vars.$border-radius;
  }
  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: vars.$tbc-color-white;
    background-color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
  }
  .btn-outline-primary {
    color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
  }
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: vars.$tbc-color-orange;
}

.input-group-text {
  background-color: vars.$tbc-color-white;
  border-right: $border-style-white;
  border-top: $border-style-blue;
  border-left: $border-style-blue;  
  border-bottom: $border-style-blue;
  border-top-left-radius: vars.$border-radius;
  border-bottom-left-radius: vars.$border-radius;
}

.form-select {
  border-top: $border-style-blue;
  border-right: $border-style-blue;  
  border-bottom: $border-style-blue; 
  border-left: $border-style-blue;
  border-radius: vars.$border-radius;
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: vars.$tbc-color-orange;
}

.form-switch {
  .form-check-label {
    color: vars.$tbc-color-new-blue;
  }
  .form-check-input:checked {
    background-color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-dark-blue;
  }
  .form-check-input {
    height: 1.5em;
    width: 2.5em;
  }
}

.btn-group>.btn-group:not(:last-child)>.btn, 
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: vars.$border-radius;
  border-bottom-right-radius: vars.$border-radius;
  border-top-left-radius: vars.$border-radius;
  border-bottom-left-radius: vars.$border-radius;
}
.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
  border-top-left-radius: vars.$border-radius;
  border-bottom-left-radius: vars.$border-radius;
  border-top-right-radius: vars.$border-radius;
  border-bottom-right-radius: vars.$border-radius;
}

.inline-button {
  .btn-outline-primary:not(:last-child) {
    margin-right: 1rem;
  }
  
  .btn-check:checked + .btn-outline-primary {
    color: #fff;
    background-color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
  }
  
  .btn-outline-primary {
    color: #000;
    border-color: vars.$tbc-color-new-blue;
  }
}

.gender-identity-field {
  .btn-outline-primary {
    border-top: $border-style-blue;
    border-right: $border-style-blue;  
    border-bottom: $border-style-blue; 
    border-left: $border-style-blue;
  }
  .btn-check:checked + .btn-outline-primary {
    background-color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
    color: #fff;
  }
  input.form-control {
    border: 2px solid vars.$tbc-color-new-blue;
    text-align: center;
    color: #0d6efd;      
  }
}

.stacked-block-button {
  .btn-outline-primary {
    border-top: $border-style-blue;
    border-right: $border-style-blue;  
    border-bottom: $border-style-blue; 
    border-left: $border-style-blue;
    border-radius: vars.$border-radius;
  }
  .btn-check:checked + .btn-outline-primary {
    background-color: vars.$tbc-color-new-blue;
    border-color: vars.$tbc-color-new-blue;
    color: #fff;
  }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: vars.$border-radius;
  }
  .btn-group > .btn:nth-child(n+3) {
    border-radius: vars.$border-radius;
  }
}

input.form-control {
  border: 2px solid vars.$tbc-color-new-blue;
}
