@use 'variables' as vars;

.btn-outline-primary {
  color: vars.$tbc-color-black;
  border-color: vars.$tbc-color-black;

  &:active {
    color: vars.$tbc-color-black;
    border-color: vars.$tbc-color-black;
  }

  &:hover {
    color: #fff;
    background-color: vars.$tbc-color-gray-600;
    border-color: vars.$tbc-color-gray-600;
  }
}

.btn-check {
  &:checked+.btn-outline-primary {
    color: #fff;
    background-color: vars.$tbc-color-black;
    border-color: vars.$tbc-color-black;
    &:focus {
      box-shadow: none;
    }
  }
}
