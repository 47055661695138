@use "../../styles/variables" as vars;

.event-filters {
  margin-bottom: 150px;
  overflow: auto;

  &_header-icon {
    padding-right: 0.75em;
  }

  &_list {
    .selected,
    .list-group-item-action:focus,
    .list-group-item-action:hover {
      background-color: vars.$tbc-color-new-blue-lighter;
    }
  }
  &_nested-list {
    margin-left: 2em;
    .selected,
    .list-group-item-action:focus,
    .list-group-item-action:hover {
      background-color: vars.$tbc-color-new-blue-lightest;
    }
  }
  &_comp-cat {
    padding: 0.5em;
  }
  &_additional {
    padding: 0.5em;
    .form-switch .form-check-label {
      color: #000;
    }
  }
  &_actions {
    padding: 1rem;
    width: 100%;
    background-color: #FFF;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}