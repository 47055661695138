@use "../../styles/variables" as vars;

.header {
  background-color: vars.$tbc-color-white;
  color: vars.$tbc-color-new-blue;
  margin-bottom: 1rem;
  height: 32px;

  &.navbar {
    padding: 0 0.5rem;
  }

  &--off-white {
    background-color: vars.$tbc-color-off-white;
  }
  &_link {
    width: 32px;
    a, a:active, a:visited {
      width: 32px;
      color: vars.$tbc-color-new-blue;
      text-decoration: none;
      font-weight: 400;
    }
  }
  &_title {
    margin: 0 auto;
    h1 {
      color: vars.$tbc-color-new-blue;
      font-size: 20px;
      margin: 0;
      padding: 6px 0;
      line-height: 1;
      font-weight: 700;
    }
  }
}
