@use "../../styles/variables" as vars;

.footer {  
  background-color: vars.$tbc-color-white;
  &.navbar {
    padding: 20px 0;
  }
  & a {
    color: vars.$tbc-color-gray-500;
    &.active-route {
      color: vars.$tbc-color-new-blue;
    }
    &:hover {
      color: vars.$tbc-color-new-blue;
    }
  }
}