@use "../../styles/variables" as vars;

.image-upload-menu {
  .modal-dialog {
    position: fixed;
    bottom: 0;
    left: 0%;
    right: 0%;
    margin-bottom: 50px;
  }
  &_label {
    padding-left: 1rem;
  }
}
#image-upload-menu_trash {
  color: vars.$tbc-color-danger;
}