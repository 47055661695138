@use "../../styles/variables" as vars;
@import 'bootstrap';

.registration {
  &_header {
    h1 {
      text-transform: uppercase;
    }
  }
  &_container {
    height: calc(100% - 32px);
    padding-top: 42px;
    text-align: center;
    .progress-bar {
      background-color: vars.$tbc-color-new-blue;
    }
  }
  &_intro {
    @extend .h-100;
    @extend .px-1; 
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-between;
    @extend .text-center;
    margin-top: 16px;
    color: vars.$tbc-color-new-blue;
  }
  &_submit-button {
    margin-top: 10px;
    margin-bottom: 2rem;
  }
}
