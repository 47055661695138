@use "../../styles/variables" as vars;

.image-grid {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
.image-grid-item {
  position: relative;
  border: 2px solid vars.$tbc-color-new-blue;
  border-radius: vars.$border-radius;
  margin: 5px;
  width: calc(100% - 10px);
  &:after {
    display: block;
    content: "";
    padding-top: calc(100% - 10px);
  }
  &:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  &_content {
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      border-radius: 0.35rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image-upload-field {
    &_dropzone {
      border: 0;
    }
  }
}