$tbc-color-white: #fff;
$tbc-color-gray-100: #f8f9fa;
$tbc-color-gray-200: #f7f7f9;
$tbc-color-gray-300: #eceeef;
$tbc-color-gray-400: #ced4da;
$tbc-color-gray-500: #adb5bd;
$tbc-color-gray-600: #919aa1;
$tbc-color-gray-700: #55595c;
$tbc-color-gray-800: #343a40;
$tbc-color-gray-900: #1a1a1a;
$tbc-color-black: #000;
$tbc-color-blue: #007bff;
$tbc-color-indigo: #6610f2;
/* $tbc-color-purple: #6f42c1; */
$tbc-color-purple: #3b2886;
$tbc-color-purple-15pclight: #9b7cd4;
$tbc-color-purple-30pclight: #C7B6E7;
$tbc-color-pink: #e83e8c;
$tbc-color-red: #d9534f;
$tbc-color-orange: #fd7e14;
$tbc-color-orange-15pclight: #FEA860;
$tbc-color-orange-30pclight: #FED2AC;
$tbc-color-yellow: #f0ad4e;
$tbc-color-green: #4bbf73;
$tbc-color-teal: #20c997;
$tbc-color-cyan: #1f9bcf;

// RE-DESIGN
$tbc-color-new-blue: #3ca6e3;
$tbc-color-dark-blue: #1d5e8e;
$tbc-color-off-white: #f2f2f7;
$tbc-color-new-blue-lighter: #c4e4f6;
$tbc-color-new-blue-lightest: #ebf6fc;
// END RE-DESIGN

$tbc-color-primary: #1a1a1a;
$tbc-color-secondary: #ffffff;
$tbc-color-success: #4bbf73;
$tbc-color-info: #1f9bcf;
$tbc-color-warning: #f0ad4e;
$tbc-color-danger: #d9534f;

$tbc-color-light: $tbc-color-white;
$tbc-color-dark: $tbc-color-gray-800;

$border-radius: 0.5rem;