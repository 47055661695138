@use "../../styles/variables" as vars;

.image-upload-field {
    &_dropzone {
        border-radius: 10px;
        padding: 1rem;
        margin: 0 auto;
        color: #999;
    }
    &_upload-icon {
        color: vars.$tbc-color-new-blue;
    }

    &_image-preview {
        width: 33.3%;
        text-align: center;
    }
}
