@use "../../styles/variables" as vars;

.profile-media-view {
  h2 {
    color: vars.$tbc-color-new-blue;
    padding: 1rem;
    font-size: 1.3em;
  }

  &_video-container {
    border: 2px solid vars.$tbc-color-new-blue;
    border-radius: vars.$border-radius;
    margin: 5px;
  }
}
